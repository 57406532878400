import { PublicPlan } from '@wix/ambassador-pricing-plans-v2-plan/types';
import { planPurchaseClick } from '@wix/bi-logger-membership/v2';
import { getCoverImage, CoverImage } from '@wix/pricing-plans-utils/client-data';
import { TFunction } from '@wix/yoshi-flow-editor';
import { ElementRole } from '../../constants/elements';
import { Analytics } from '../../services/analytics';
import { encodeBase64Url } from '../../services/encode-base64';
import { getFreeTrialDaysLabel, getPeriodLabel, getPlanDuration } from '../../utils';
import model from './model';

export default model.createController(({ $w, flowAPI }) => {
  const setPlanData = async (plan: PublicPlan) => {
    const t = flowAPI.translations.t as TFunction;
    const coverImage = getCoverImage(plan.clientData);
    if (coverImage) {
      $w(`#${ElementRole.Image}`).src = getImageSrc(coverImage);
    }
    $w(`#${ElementRole.Image}`).expand();

    setDescription(plan);

    const formattedPrice = flowAPI.formatCurrency({
      value: plan.pricing!.price!.value!,
      currency: plan.pricing!.price!.currency!,
    }) as string;
    setA11yAttributes(plan, formattedPrice);

    const freeTrial = getFreeTrialDaysLabel(plan, t);
    const duration = getPlanDuration(plan, t);
    const planDuration = freeTrial ? `${duration}\n${freeTrial}` : duration;

    $w(`#${ElementRole.PlanName}`).text = plan.name!;
    $w(`#${ElementRole.PlanDuration}`).text = planDuration;

    const cycle = getPeriodLabel(plan.pricing?.subscription, t) ?? '';
    $w(`#${ElementRole.PricingWidget}`).setPricing(plan.pricing?.price?.value, plan.pricing?.price?.currency, cycle);

    if (plan.perks?.values?.length) {
      $w(`#${ElementRole.PerksWidget}`).setPerks(plan.perks?.values);
    } else {
      $w(`#${ElementRole.ContentDividerContainer}`).collapse();
      $w(`#${ElementRole.PerksWidget}`).collapse();
    }
  };

  const setDescription = (plan: PublicPlan) => {
    if (plan.description) {
      $w(`#${ElementRole.Description}`).text = plan.description;
    } else {
      $w(`#${ElementRole.Description}`).text = '';
      $w(`#${ElementRole.Description}`).collapse();
    }
  };
  const navigateToCheckout = (plan: PublicPlan) => {
    const analytics = new Analytics(flowAPI.controllerConfig.wixCodeApi.window);
    analytics.clickProduct(plan);
    flowAPI?.bi?.report(planPurchaseClick({ planGuid: plan.id ?? '', widgetType: 'single' }));
    const checkoutData = { planId: plan.id!, integrationData: {} };
    setCurrentPath('/payment/' + encodeBase64Url(checkoutData) + '#membershipPlanPickerTpa1');
  };

  const setCurrentPath = async (path: string) => {
    const { relativeUrl } = await flowAPI.controllerConfig.wixCodeApi.site.getSectionUrl({
      appDefinitionId: '1522827f-c56c-a5c9-2ac9-00f9e6ae12d3',
      sectionId: 'membership_plan_picker_tpa',
    });
    flowAPI.controllerConfig.wixCodeApi.location.to!((relativeUrl ?? '') + path);
  };

  const setA11yAttributes = (plan: PublicPlan, formattedPrice: string) => {
    // @ts-expect-error
    $w(`#${ElementRole.PlanContainer}`).accessibility.ariaAttributes.label = plan.name;

    if ($w(`#${ElementRole.BadgeWidget}`).isVisible) {
      const ribbonTextElement = $w(`#${ElementRole.BadgeWidget}`).getTextElement();
      // @ts-expect-error
      $w(`#${ElementRole.PlanContainer}`).accessibility.ariaAttributes.describedBy = ribbonTextElement;
    }

    if ($w(`#${ElementRole.PricingWidget}`).isVisible) {
      // @ts-expect-error
      $w(`#${ElementRole.PlanName}`).accessibility.screenReader.suffix = formattedPrice;
    }
  };

  return {
    pageReady: () => {
      if (!flowAPI.environment.isEditor) {
        $w(`#${ElementRole.Image}`).collapse();
      }
    },
    exports: {
      setPlan: async (plan: PublicPlan) => {
        await setPlanData(plan);
        $w(`#${ElementRole.Button}`).onClick(() => navigateToCheckout(plan));
      },
    },
  };
});

function getImageSrc({ uri, width, height }: CoverImage): string {
  return `wix:image://v1/${uri}/${uri}#originWidth=${width}&originHeight=${height}`;
}
