import currencyToSymbolMap from 'currency-symbol-map/map';
import model from './model';

export enum ElementId {
  Price = '#price',
  Currency = '#currency',
  Frequency = '#frequency',
}

export default model.createController(({ $w }) => {
  return {
    pageReady: async () => {},
    exports: {
      setPricing: (price: string, currency: string, paymentFrequency: string) => {
        $w(ElementId.Price).text = price;
        $w(ElementId.Currency).text = currencyToSymbolMap[currency];

        if (paymentFrequency) {
          $w(ElementId.Frequency).text = paymentFrequency;
        } else {
          $w(ElementId.Frequency).text = '';
          $w(ElementId.Frequency).collapse();
        }
      },
    },
  };
});
